export { matchers } from './client-matchers.js';

export const components = [
	() => import("../../src/routes/__layout.svelte"),
	() => import("../runtime/components/error.svelte"),
	() => import("../../src/routes/order/[orderId]/__layout.svelte"),
	() => import("../../src/routes/report/__layout.svelte"),
	() => import("../../src/routes/auth/fortnox.svelte"),
	() => import("../../src/routes/auth/login.svelte"),
	() => import("../../src/routes/auth/update-token.svelte"),
	() => import("../../src/routes/car/[id]/index.svelte"),
	() => import("../../src/routes/index.svelte"),
	() => import("../../src/routes/job/[id]/help/index.svelte"),
	() => import("../../src/routes/job/[id]/index.svelte"),
	() => import("../../src/routes/order/[orderId]/action-assign-invoice/index.svelte"),
	() => import("../../src/routes/order/[orderId]/action-update-order/index.svelte"),
	() => import("../../src/routes/order/[orderId]/action-update-status/index.svelte"),
	() => import("../../src/routes/order/[orderId]/index.svelte"),
	() => import("../../src/routes/order/[orderId]/info.svelte"),
	() => import("../../src/routes/order/[orderId]/invoice/[invoiceId]/assign.svelte"),
	() => import("../../src/routes/order/[orderId]/invoice/[invoiceId]/bookkeep.svelte"),
	() => import("../../src/routes/order/[orderId]/invoice/[invoiceId]/delete.svelte"),
	() => import("../../src/routes/order/[orderId]/invoice/[invoiceId]/index.svelte"),
	() => import("../../src/routes/order/[orderId]/invoice/new.svelte"),
	() => import("../../src/routes/order/[orderId]/job/[jobId]-assign-location.svelte"),
	() => import("../../src/routes/order/[orderId]/job/[jobId]-assign-transaction.svelte"),
	() => import("../../src/routes/order/[orderId]/job/[jobId]-delete.svelte"),
	() => import("../../src/routes/order/[orderId]/job/[jobId]-duplicate.svelte"),
	() => import("../../src/routes/order/[orderId]/job/[jobId].svelte"),
	() => import("../../src/routes/order/[orderId]/job/new.svelte"),
	() => import("../../src/routes/order/[orderId]/location/[locationId]-delete.svelte"),
	() => import("../../src/routes/order/[orderId]/location/[locationId].svelte"),
	() => import("../../src/routes/order/[orderId]/location/new.svelte"),
	() => import("../../src/routes/order/[orderId]/overview.svelte"),
	() => import("../../src/routes/order/[orderId]/storage/[storageId]-assign-transaction.svelte"),
	() => import("../../src/routes/order/[orderId]/storage/[storageId]-delete.svelte"),
	() => import("../../src/routes/order/[orderId]/storage/[storageId].svelte"),
	() => import("../../src/routes/order/[orderId]/storage/new.svelte"),
	() => import("../../src/routes/order/[orderId]/transaction/[transactionId]-delete.svelte"),
	() => import("../../src/routes/order/[orderId]/transaction/[transactionId].svelte"),
	() => import("../../src/routes/order/[orderId]/transaction/new.svelte"),
	() => import("../../src/routes/order/new/index.svelte"),
	() => import("../../src/routes/planning/index.svelte"),
	() => import("../../src/routes/report/index.svelte"),
	() => import("../../src/routes/report/job/[notionJobId].svelte"),
	() => import("../../src/routes/report-time/[id].svelte"),
	() => import("../../src/routes/report-time/new/index.svelte"),
	() => import("../../src/routes/rut/index.svelte")
];

export const dictionary = {
	"": [[0, 8], [1]],
	"planning": [[0, 39], [1], 1],
	"report": [[0, 3, 40], [1]],
	"rut": [[0, 44], [1], 1],
	"auth/fortnox": [[0, 4], [1]],
	"auth/login": [[0, 5], [1], 1],
	"auth/update-token": [[0, 6], [1]],
	"order/new": [[0, 38], [1], 1],
	"report-time/new": [[0, 43], [1], 1],
	"report/job/[notionJobId]": [[0, 3, 41], [1], 1],
	"car/[id]": [[0, 7], [1], 1],
	"job/[id]": [[0, 10], [1], 1],
	"order/[orderId]": [[0, 2, 14], [1]],
	"report-time/[id]": [[0, 42], [1], 1],
	"job/[id]/help": [[0, 9], [1], 1],
	"order/[orderId]/action-assign-invoice": [[0, 2, 11], [1], 1],
	"order/[orderId]/action-update-order": [[0, 2, 12], [1], 1],
	"order/[orderId]/action-update-status": [[0, 2, 13], [1]],
	"order/[orderId]/info": [[0, 2, 15], [1], 1],
	"order/[orderId]/overview": [[0, 2, 30], [1], 1],
	"order/[orderId]/invoice/new": [[0, 2, 20], [1], 1],
	"order/[orderId]/job/new": [[0, 2, 26], [1], 1],
	"order/[orderId]/location/new": [[0, 2, 29], [1], 1],
	"order/[orderId]/storage/new": [[0, 2, 34], [1], 1],
	"order/[orderId]/transaction/new": [[0, 2, 37], [1], 1],
	"order/[orderId]/job/[jobId]-assign-location": [[0, 2, 21], [1], 1],
	"order/[orderId]/job/[jobId]-assign-transaction": [[0, 2, 22], [1], 1],
	"order/[orderId]/job/[jobId]-delete": [[0, 2, 23], [1], 1],
	"order/[orderId]/job/[jobId]-duplicate": [[0, 2, 24], [1], 1],
	"order/[orderId]/location/[locationId]-delete": [[0, 2, 27], [1], 1],
	"order/[orderId]/storage/[storageId]-assign-transaction": [[0, 2, 31], [1], 1],
	"order/[orderId]/storage/[storageId]-delete": [[0, 2, 32], [1], 1],
	"order/[orderId]/transaction/[transactionId]-delete": [[0, 2, 35], [1], 1],
	"order/[orderId]/invoice/[invoiceId]": [[0, 2, 19], [1], 1],
	"order/[orderId]/job/[jobId]": [[0, 2, 25], [1], 1],
	"order/[orderId]/location/[locationId]": [[0, 2, 28], [1], 1],
	"order/[orderId]/storage/[storageId]": [[0, 2, 33], [1], 1],
	"order/[orderId]/transaction/[transactionId]": [[0, 2, 36], [1], 1],
	"order/[orderId]/invoice/[invoiceId]/assign": [[0, 2, 16], [1], 1],
	"order/[orderId]/invoice/[invoiceId]/bookkeep": [[0, 2, 17], [1], 1],
	"order/[orderId]/invoice/[invoiceId]/delete": [[0, 2, 18], [1], 1]
};